import React, { useMemo, useState } from "react";
import moment from "moment";

function BetResultHistory({ resultHistory, publicKey }) {
    const [selected, setSelected] = useState("All Bets");
    const titles = ["All Bets", "Lucky Bets", "Your Bets"];

    const history = useMemo(()=>{
        if (selected === "All Bets") {
            return resultHistory;
        } else if(selected === "Lucky Bets") {
            return resultHistory.filter((element)=> element.result === "win") 
        } else {
            return resultHistory.filter((element)=> element.address === publicKey.toString()) 
        }
    },[resultHistory, titles])
    return (
        <div className="mt-6">
            <div className="space-x-8 flex items-center justify-start px-3">
                {titles.map((title, index) => (
                    <div key={index} className={`${selected === title ? "over-gradient rounded-lg" : ""} p-2 cursor-pointer`} onClick={() => setSelected(title)}>
                        {title}
                    </div>
                ))}
            </div>
            <div className="space-y-2">
                {history.length === 0 ? (
                    <p className="py-3"></p>
                ) : (
                    history.map((entry, index) => (
                        <div key={index} className={`p-3 rounded-lg flex justify-between items-center`}>
                            <div className="flex items-center space-x-6 md:space-x-32 xl:space-x-60">
                                <p className="font-semibold text-yellow-400">{entry?.address.slice(0, 4) + "..."}</p>
                                <p className={`text-sm`}>{entry.result === "win" ? "won" : "lost"}</p>
                                <div className={`flex items-center justify-start rounded-lg px-1 ${entry.result === "win" ? "bg-[#4ade8080]" : "bg-[#9ca3af78]"}`}>
                                    <img className="w-6" src="assets/imgs/sol.png" />
                                    <p className="text-white font-semibold text-sm">{entry.amount} SOL</p>
                                </div>
                                {entry.result === "win" && <p className="text-gray-400 text-sm">({entry.multiplier.toFixed(2)}x)</p>}
                            </div>
                            <p className="text-gray-400 text-sm">{moment(entry.timestamp).fromNow()}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default BetResultHistory;
