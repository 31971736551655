// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey : "AIzaSyDHYGph0QGSx3ZK7Z8cPTmsric0OUd3QB4" , 
  authDomain:"dice-70dc6.firebaseapp.com", 
  databaseURL: "https://dice-70dc6-default-rtdb.firebaseio.com/",
  projectId:"dice-70dc6", 
  storageBucket:"dice-70dc6.appspot.com", 
  messagingSenderId:"952437411259", 
  appId: "1:952437411259:web:a02847a9bea6d93b86489e",
  measurementId: "G-8PTLR1L62E"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
