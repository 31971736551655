import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import React from "react";

function Header({ balance }) {
    return (
        <header className="fixed top-0 backdrop-blur-md bg-transparent rounded-b-lg z-10">
            <div className="max-w-8xl mx-auto px-8 flex justify-between items-center w-screen">
                <img className="w-28" src="./assets/imgs/logo.png" />
                <div className="flex items-center justify-end">
                    <div className="flex items-center sm:w-60 text-white rounded h-6 pl-3 pr-3">
                        <div className="sm:flex items-center w-full bg-gray-700 rounded-full overflow-hidden h-6 mr-2 border border-gray-600 hidden">
                            <div className="bg-red-500 h-6 flex items-center justify-center text-sm w-1/2">50%</div>
                            <div className="bg-black h-6 flex items-center justify-center text-sm w-1/2">50%</div>
                        </div>
                    </div>
                    <div className="text-white pr-2 text-md">
                        {balance !== null ? `${balance.toFixed(3)} sol` : null}
                    </div>
                    <WalletMultiButton
                        style={{ background: "none", border: "1px solid", fontSize: "12px" }}
                    ></WalletMultiButton>
                </div>
            </div>
        </header>
    );
}

export default Header;
