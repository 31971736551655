import React from "react";

const ProgressBar = ({ activeValue = 2, setActiveValue, rollState }) => {
    return (
        <>
            <div className="container">
                <div className="progress-bar">
                    <ul className="progress text-2xl">
                        {new Array(5).fill(1).map((item, index) => {
                            return (
                                <li
                                    onClick={() => setActiveValue(index)}
                                    className={activeValue === index ? "active" : ""}
                                    key={index}
                                >
                                    {/* <span>{index * 25}</span> */}
                                </li>
                            );
                        })}
                    </ul>
                    <div className="bar" style={{ background: "#f24a74", border: "solid 1px #4b5563", boxShadow: "0 0 5px #bbbbbb" }}>
                        <div
                            className="fill"
                            style={{
                                width: `${(activeValue / 4) * 100}%`,
                                height: "12px",
                                backgroundColor: "#4d4ce0", // Set the fill color dynamically
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
