import React from 'react';

const Ruler = () => {
  return (
    <div className="horizontal-ruler mt-2 bg-transparent">
      {[...Array(100)].map((_, i) => (
        <div
          key={i}
          className={`ruler-mark ${i % 5 === 0 ? 'long-mark' : ''}`} // Add 'long-mark' for multiples of 5
        />
      ))}
    </div>
  );
};

export default Ruler;
