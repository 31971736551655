import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import WalletProvider from "./WalletProvider";
import "@solana/wallet-adapter-react-ui/styles.css";

import { Buffer } from "buffer";
import process from "process";

window.Buffer = Buffer;
window.process = process;

ReactDOM.render(
    <React.StrictMode>
        <WalletProvider>
            <App />
        </WalletProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);
