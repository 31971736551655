import { useWallet } from "@solana/wallet-adapter-react";
import { limitToLast, onValue, orderByChild, query, ref } from "firebase/database";
import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loading from "./assets/loading.json";
import BetComponent from "./components/BetComponent";
import BetResultHistory from "./components/BetResultHistory";
import Header from "./components/Header";
import InifinitCount from "./components/InifinitCount";
import LastWins from "./components/LastWins";
import { database } from "./firebase"; // Ensure correct path
import useSolana from "./useSolana";

import AOS from "aos";
import "aos/dist/aos.css";

function App() {
    const [resultHistory, setResultHistory] = useState([]); // Track bet history
    const [activeValue, setActiveValue] = useState(2);
    const [rollState, setRollState] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const [balance, setBalance] = useState(null);
    const [houseSol, setHouseSol] = useState(null);
    const { publicKey } = useWallet(); // Access the wallet context
    const { connection, houseAccount } = useSolana();
    const betRef = useRef();
    const lastWinRef = useRef();

    const [result, setResult] = useState({
        isShow: false,
        randomNumber: "",
        isWin: "",
    });

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    useEffect(() => {
        console.log(betRef.current.offsetHeight);
        lastWinRef.current.style.height = betRef.current.offsetHeight + "px";
    }, []);

    useEffect(() => {
        let intervalId;

        const getBalance = async () => {
            if (publicKey) {
                try {
                    // Fetch the SOL balance of the connected wallet
                    const balanceLamports = await connection.getBalance(publicKey);
                    const balanceSol = balanceLamports / 1e9; // Convert lamports to SOL
                    setBalance(balanceSol);

                    const houseLamports = await connection.getBalance(houseAccount);
                    const houseSol = houseLamports / 1e9; // Convert lamports to SOL
                    setHouseSol(houseSol.toFixed(2));
                } catch (err) {
                    console.error("Failed to fetch balance:", err);
                }
            }
        };

        if (publicKey) {
            getBalance(); // Initial call to get the balance right away
            intervalId = setInterval(getBalance, 5000); // Call getBalance every 5 seconds
        }

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [publicKey, connection, houseAccount]); // Add dependencies for updates

    useEffect(() => {
        async function getLatestGameResults() {
            try {
                // Reference to the 'gameResults' node
                const gameResultsRef = ref(database, "gameResults");

                // Query to get the last 15 entries ordered by the 'timestamp' field
                const latestEntriesQuery = query(gameResultsRef, orderByChild("timestamp"), limitToLast(50));

                // Listen for real-time updates using 'onValue'
                onValue(latestEntriesQuery, (snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();

                        const entries = Object.entries(data)
                            .map(([key, value]) => ({
                                id: key, // Keep the key for reference
                                ...value, // Spread the rest of the data
                            }))
                            .sort((a, b) => b?.timestamp - a?.timestamp);
                        setResultHistory(entries);
                    } else {
                        setResultHistory([]);
                    }
                });
            } catch (error) {
                console.error("Error fetching latest game results:", error);
            }
        }
        getLatestGameResults();
        return () => {};
    }, []);

    return (
        <div>
            <div className="min-h-screen back-main text-white flex flex-col items-center justify-center  px-8">
                <Header balance={balance} />
                <div className="w-full mt-24 grid grid-cols-12 gap-4">
                    <div ref={betRef} className="col-span-12 lg:col-span-9 h-fit">
                        <div className="w-full relative">
                            <img src="./assets/imgs/back.png" className="w-full rounded-xl" />
                            <div className="left-bar h-3/5 md:h-1/2"></div>
                            <div className="right-bar h-3/5 md:h-1/2"></div>
                            <div className="absolute right-0 top-0 grid grid-cols-4 gap-2 p-3">
                                <div className="bg-[#4446a9] border-2 border-[#5659d5] p-2 rounded-md">x0.97</div>
                                <div className="bg-[#4446a9] border-2 border-[#5659d5] p-2 rounded-md">x.3</div>
                                <div className="bg-[#b53a6a] border-2 border-[#d74a81] p-2 rounded-md">x1.95</div>
                                <div className="bg-[#b53a6a] border-2 border-[#d74a81] p-2 rounded-md">x3.9</div>
                            </div>
                            <div className="absolute w-full left-0 flex items-center justify-between top-1/2 transform -translate-y-1/2 px-10">
                                <div className="text-center">
                                    <p className="text-base lg:text-2xl xl:text-3xl">FORECAST</p>
                                    <p className="text-5xl lg:text-6xl xl:text-7xl font-semibold">{activeValue * 25}</p>
                                </div>

                                <div className="text-center">
                                    <p className="text-base lg:text-2xl xl:text-3xl">LUCKY NUMBER</p>
                                    {result?.isShow && result?.randomNumber ? (
                                        <div className="text-5xl lg:text-6xl xl:text-7xl font-semibold">{result?.randomNumber}</div>
                                    ) : (
                                        <InifinitCount />
                                    )}
                                </div>
                            </div>
                            <div className="text-xl lg:text-2xl font-semibold absolute bottom-6 left-1/2 transform -translate-x-1/2">
                                {result?.isShow ? (
                                    result?.isWin ? (
                                        <span>You Won!</span>
                                    ) : (
                                        <span>You Lost</span>
                                    )
                                ) : rollState < 0 ? (
                                    <div key={Math.random()}>
                                        Roll Under Set To {activeValue * 25}!
                                    </div>
                                ) : (
                                    <div key={Math.random()}>
                                        Roll Over Set To {activeValue * 25}!
                                    </div>
                                )}
                            </div>
                        </div>
                        <BetComponent
                            activeValue={activeValue}
                            setActiveValue={setActiveValue}
                            rollState={rollState}
                            setIsLoading={setIsLoading}
                            setRollState={setRollState}
                            balance={balance}
                            result={result}
                            setResult={setResult}
                            isLoading={isLoading}
                            publicKey={publicKey}
                        />
                    </div>
                    <div ref={lastWinRef} className="hidden lg:block lg:col-span-3 bg-[#1e235a] rounded-xl z-[1] !h-full overflow-hidden">
                        <LastWins resultHistory={resultHistory} />
                    </div>
                </div>
                <div className="my-6 rounded-xl back-contain w-full">
                    <div className="w-full px-2">
                        <BetResultHistory resultHistory={resultHistory} publicKey={publicKey} />
                    </div>
                </div>
            </div>
            <div className="text-4xl fixed top-28 text-white backdrop-blur-sm">{houseSol ? <p className="handjet sol-amount">{houseSol + "SOL"}</p> : null}</div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    );
}

export default App;
