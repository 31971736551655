import React, { useEffect, useState } from "react";

function InifinitCount() {
    const [count, setCount] = useState(1);
    useEffect(() => {
        const timer = setInterval(() => {
            setCount(previousCount => 
                previousCount >= 100 ? 1 : previousCount + 1)
        }, 50);
    
        return () => clearInterval(timer);
    }, []);

    
    return (
        <div className="text-5xl lg:text-6xl xl:text-7xl font-semibold">{count}</div>
    );
}

export default InifinitCount;
