import React from "react";

function LastWins({ resultHistory }) {
    const result = (resultHistory ?? []).filter(item=>item.result === "win")
    return (
        <div className="mt-6">
            <h4 className="text-2xl p-3">Last Wins</h4>
            <div>
                {resultHistory.length === 0 ? (
                    <p className="px-3">No bets yet.</p>
                ) : (
                    result.map((entry, index) => (
                        <div key={index} className={`p-3 rounded-lg`}>
                            <div className="flex items-center space-x-2 justify-between">
                                <p className="font-semibold text-yellow-400 text-xs">{entry?.address.slice(0, 4) + "..."}</p>
                                {entry.result === "win" && <p className="text-gray-400 text-xs">({entry.multiplier.toFixed(2)}x)</p>}
                                <div className={`flex items-center justify-start rounded-lg px-1`}>
                                    <img className="w-6" src="assets/imgs/sol.png" />
                                    <p className="text-white font-semibold text-xs">{entry.amount * entry.multiplier.toFixed(2)} SOL</p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default LastWins;
