import React, { useMemo, useState } from "react";
import ProgressBar from "./ProgressBar";
// @ts-ignore
import Ruler from "./Ruler";
import useSolana from "../useSolana";
import { toast } from "react-toastify";

function BetComponent({ activeValue, setActiveValue, rollState, setResult, setRollState, setIsLoading, balance, isLoading, result, publicKey }) {
    const [isManual, setIsManual] = useState(false);
    const [betAmount, setBetAmount] = useState(0.1);
    const [winChance, setWinChance] = useState(null);
    const { placeBet } = useSolana();

    const multiplier = useMemo(() => {
        const value = activeValue * 25;
        const baseMultiplier = rollState > 0 ? (100 - value) / 100 : value / 100;
        setWinChance(baseMultiplier * 100);
        return baseMultiplier !== 0 ? (1 / baseMultiplier).toFixed(2) * (1 - 0.025) : 0.975;
    }, [activeValue, rollState]);

    const handleSubmit = async () => {
        console.log("Bet Amount Submitted:", betAmount, balance);
        if (!publicKey) {
            toast.warn("No wallet is connected!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
        if (balance < betAmount) {
            toast.warn("💸 Insufficient funds!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
        if (betAmount >= 0.1) {
            if (betAmount && rollState && activeValue && multiplier) {
                try {
                    setIsLoading(true);
                    const result = await placeBet(betAmount, rollState, activeValue * 25, multiplier);
                    if (result !== false) {
                        const { isWin, randomNumber } = result;
                        setIsLoading(false);
                        setResult({ isWin: isWin, randomNumber: randomNumber, isShow: true });
                    } else {
                        toast.info("🚫 You canceled the bet!", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                        setIsLoading(false);
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            }
        }
    };

    return (
        <div className="w-full rounded-xl mt-4">
            <ProgressBar activeValue={activeValue} setActiveValue={setActiveValue} rollState={rollState} />
            <Ruler />
            <div className="grid grid-cols-4 mt-2 text-center h-16 text-base md:text-lg lg:text-xl">
                <div onClick={() => setRollState(-1)} className="over-gradient flex items-center justify-center flex-col rounded-tl-lg rounded-bl-lg cursor-pointer">
                    Roll Under
                </div>
                <div className="bg-[#23295b] flex items-center justify-center flex-col border-r border-[#ffffff0f]">
                    <span className="text-sm md:text-base lg:text-lg">Multiplier</span>
                    <span className="text-2xl font-bold">x{multiplier}</span>
                </div>
                <div className="bg-[#23295b] flex items-center justify-center flex-col">
                    <span className="text-sm md:text-base lg:text-lg">Win Chance</span>
                    <span className="text-2xl font-bold">{winChance}%</span>
                </div>
                <div onClick={() => setRollState(1)} className="under-gradient flex items-center justify-center flex-col rounded-tr-lg rounded-br-lg cursor-pointer">
                    Roll Over
                </div>
            </div>
            <div className="my-4">
                <span className="text-xl font-semibold">Make a Bet</span>
            </div>
            <div className="grid grid-cols-3 gap-6 h-16">
                <div className="grid grid-cols-2 back-contain rounded-lg text-center">
                    <div onClick={() => setIsManual(true)} className={(isManual ? "over-gradient rounded-lg " : "") + `flex items-center justify-center cursor-pointer`}>
                        Manual
                    </div>
                    <div onClick={() => setIsManual(false)} className={(!isManual ? "over-gradient rounded-lg " : "") + `flex items-center justify-center cursor-pointer`}>
                        Auto
                    </div>
                </div>
                <div className="rounded-lg back-contain flex items-center justify-between px-3">
                    {isManual ? (
                        <input
                            type="number"
                            placeholder="Enter your bet"
                            value={betAmount}
                            onChange={(e) => {
                                if (isNaN(e.target.value) || e.target.value < 0.1) {
                                    setBetAmount(0.1);
                                } else {
                                    if (e.target.value > 2) {
                                        setBetAmount(2);
                                    } else {
                                        setBetAmount(e.target.value);
                                    }
                                }
                            }}
                            className="w-full p-2 bg-transparent text-white border border-gray-600 rounded-sm focus:outline-none"
                        />
                    ) : (
                        <>
                            <span className="text-lg">{betAmount}SOL</span>
                            <div className="text-xs grid grid-cols-4 gap-2 text-center">
                                <span onClick={() => setBetAmount(0.1)} className="bg-[#282d7a] border border-[#34387f] rounded p-1 cursor-pointer">
                                    Min
                                </span>
                                <span
                                    onClick={() => setBetAmount((pre) => (pre / 2 < 0.1 ? 0.1 : pre / 2))}
                                    className="bg-[#282d7a] border border-[#34387f] rounded p-1 cursor-pointer"
                                >
                                    1/2
                                </span>
                                <span
                                    onClick={() => setBetAmount((pre) => (pre * 2 > 2 ? 2 : pre * 2))}
                                    className="bg-[#282d7a] border border-[#34387f] rounded p-1 cursor-pointer"
                                >
                                    2x
                                </span>
                                <span onClick={() => setBetAmount(2)} className="bg-[#282d7a] border border-[#34387f] rounded p-1 cursor-pointer">
                                    Max
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <div
                    onClick={() => {
                        if (!isLoading) {
                            if (result?.isShow) {
                                setResult((pre) => ({ ...pre, randomNumber: "", isShow: false }));
                            } else {
                                handleSubmit();
                            }
                        }
                    }}
                    className="over-gradient rounded-lg flex items-center justify-center flex-col text-base md:text-lg lg:text-xl cursor-pointer"
                >
                    {isLoading ? <div className="w-12 h-12 border-4 border-white border-dashed rounded-full animate-spin"></div> : result?.isShow ? "Bet again" : "Place Bet"}
                </div>
            </div>
        </div>
    );
}

export default BetComponent;
